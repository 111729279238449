<template>
  <v-container>
    <v-layout row wrap>
      <!--<v-flex xs12>
        Amount in account: {{account.accountCurrency}} {{account.accountAmount}}
      </v-flex>
      <v-flex xs12>
        Account Type: {{account.accountType}}
      </v-flex>
      <v-flex xs12>
        Account Description: {{account.accountDescription}}
      </v-flex>-->
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    props: ['expenseId'],
    data() {
      return {
        expense: []
      }
    },
    beforeMount() {
      let exp = this.$store.state.Expenses
      for (let i = 0; i < exp.length; i++) {
        if (exp[i].id === this.expenseId) {
          this.expense = exp[i]
          break
        }
      }
    }
  }
</script>
